import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Pagination } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, left_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { EventCard } from "../../app/components";
import Inbox from '@mui/icons-material/Inbox';
import moment from 'moment';
import Logo from "../../app/components/tickit-white-transparent.png"
import { getFeaturedEvents, searchEvents } from '../../app/store/actions/eventActions';
import { Image } from 'antd';
import ReactGA from 'react-ga4';

export const Home = ({ events, searchEvents, getFeaturedEvents, user, token }) => {

    const navigate = useNavigate()
    const eventId = useParams().id
    const role = token?.split(" ")[0]

    React.useEffect(() => {
        getFeaturedEvents({})
        ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    }, [])

    return (
        <Container component="main" maxWidth="xl">
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: "100vh", mt: -2, backgroundImage: "url(https://tickit-media.s3.eu-central-1.amazonaws.com/images/Background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <BrowserView>
                        <Grid container sx={{ ...centered_flex_box, flexDirection: "row", minHeight: "100vh" }}>
                            <Grid item xs={3} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                <Typography sx={{ color: "var(--primaryColor)", fontWeight: "bold", fontSize: 40 }}>WELCOME TO</Typography>
                                <Image preview={false} src={Logo} height="60px" width="160px" style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={9} sx={centered_flex_box}>
                            </Grid>
                        </Grid>
                    </BrowserView>
                    <MobileView>
                        <Grid container sx={{ ...centered_flex_box, flexDirection: "row", minHeight: "80vh" }}>
                            <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                <Typography sx={{ color: "var(--primaryColor)", fontWeight: "bold", fontSize: 40 }}>WELCOME TO</Typography>
                                <Image preview={false} src={Logo} height="60px" width="160px" style={{ cursor: "pointer" }} />
                            </Grid>
                        </Grid>
                    </MobileView>
                </Box>
                {(!role || role !== "Partner") && <>
                    <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column" }}>
                        <Typography fontSize={30} fontWeight="bold" textAlign="center">FEATURED EVENTS</Typography>
                        <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
                    </Box>
                    {events?.results && Array.isArray(events.results) && !events.isLoading ? (
                        <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 0 : 3}>
                            {events?.results?.filter((event) => moment().isBefore(event.date)).length > 0 && events?.results?.filter((event) => moment().isBefore(event.date)).map((event) => {
                                return (
                                    <Grid item xs={isMobile ? 12 : 3} sx={centered_flex_box}>
                                        <EventCard event={event} />
                                    </Grid>
                                )
                            })}
                            {events?.results?.filter((event) => moment().isBefore(event.date)).length === 0 && (
                                <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                    <Inbox fontSize="large" />
                                    <Typography fontSize={40}>No results</Typography>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Box sx={centered_flex_box}>
                            <CircularProgress sx={{ color: "var(--secColor)" }} />
                        </Box>
                    )}
                </>}
                {(!role || role !== "Partner") && <>
                    <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column" }}>
                        <Typography fontSize={30} fontWeight="bold" textAlign="center">PAST EVENTS</Typography>
                        <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
                    </Box>
                    {events?.results && Array.isArray(events.results) && !events.isLoading ? (
                        <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 0 : 3}>
                            {events?.results?.filter((event) => moment().isAfter(event.date)).length > 0 && events?.results?.filter((event) => moment().isAfter(event.date)).map((event) => {
                                return (
                                    <Grid item xs={isMobile ? 12 : 3} sx={centered_flex_box}>
                                        <EventCard event={event} />
                                    </Grid>
                                )
                            })}
                            {events?.results?.filter((event) => moment().isAfter(event.date)).length === 0 && (
                                <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                    <Inbox fontSize="large" />
                                    <Typography fontSize={40}>No results</Typography>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Box sx={centered_flex_box}>
                            <CircularProgress sx={{ color: "var(--secColor)" }} />
                        </Box>
                    )}
                </>}
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    events: state?.events,
    user: state?.auth?.user,
    token: state?.auth?.token
});

const mapDispatchToProps = { getFeaturedEvents };

export default connect(mapStateToProps, mapDispatchToProps)(Home);