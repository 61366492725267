import * as React from "react";
import { TableRow, TableCell, Button, Modal, Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import { centered_flex_box, main_button } from "../../../app/components/Styles";
import {
  acceptTicketRequest,
  fulfillTicketRequest,
  rejectTicketRequest,
} from "../../../app/store/actions/adminActions";
import ActionDialog from "../../../app/components/ActionDialog";
import moment from "moment";
import { Image } from "antd";

export const RequestRow = ({
  request,
  action,
  setAction,
  acceptTicketRequest,
  fulfillTicketRequest,
  rejectTicketRequest,
}) => {
  const [fulfillDialog, setFulfillDialog] = React.useState(false);
  const [rejectDialog, setRejectDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleAccept = async (request) => {
    acceptTicketRequest({ requestId: request._id, eventId: request.event._id });
    await timeout(500);
    setAction(!action);
  };

  const handleReject = async (request) => {
    rejectTicketRequest({ requestId: request._id, eventId: request.event._id });
    await timeout(500);
    setAction(!action);
  };

  const handleFulfill = async (request) => {
    fulfillTicketRequest({
      requestId: request._id,
      eventId: request.event._id,
      ticketId: request.ticket._id,
    });
    await timeout(500);
    setAction(!action);
  };

  return (
    <>
      <TableRow
        key={request._id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {/* <TableCell component="th" scope="row">
          {request._id}
        </TableCell> */}
        <TableCell align="left">{request.userInfo.name}</TableCell>
        <TableCell align="left">{request.userInfo.email}</TableCell>
        <TableCell align="left">{request.userInfo.phoneNumber}</TableCell>
        <TableCell align="left">{moment(request.deadline).fromNow()}</TableCell>
        <TableCell align="left">{request.category}</TableCell>
        <TableCell align="left">{request.status}</TableCell>
        <TableCell align="left">
          {request.status === "Unseen" && (
            <Button
              onClick={() => handleAccept(request)}
              sx={{ ...main_button, m: 1 }}
            >
              Accept
            </Button>
          )}
          {request.status !== "Purchased" && request.status !== "Rejected" && (
            <Button
              onClick={() => setRejectDialog(true)}
              sx={{ ...main_button, m: 1 }}
            >
              Reject
            </Button>
          )}
          {request.status === "Accepted" &&
            request.event.paymentMethod === "InstaPay" && (
              <Button
                onClick={() => setOpen(true)}
                sx={{ ...main_button, m: 1 }}
              >
                View Receipt
              </Button>
            )}
          {request.status === "Accepted" &&
            (request.event.paymentMethod === "Cash" || request.event.paymentMethod === "InstaPay") && (
              <Button
                onClick={() => setFulfillDialog(true)}
                sx={{ ...main_button, m: 1 }}
              >
                Validate Ticket
              </Button>
            )}
        </TableCell>
      </TableRow>
      <ActionDialog
        open={rejectDialog}
        handleClose={() => setRejectDialog(false)}
        handleAccept={() => handleReject(request)}
        title={`Reject ${request.userInfo.name}'s request?`}
        content={"Rejecting their request will notify them and delete their ticket if one is already issued. This action is irreversible"}
      />
      <ActionDialog
        open={fulfillDialog}
        handleClose={() => setFulfillDialog(false)}
        handleAccept={() => handleFulfill(request)}
        title={`Fulfill ${request.userInfo.name}'s request?`}
        content={"Validating their request will validate their ticket and send it by email. This action is irreversible"}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="modal-style" sx={centered_flex_box}>
          <Box sx={{ ...centered_flex_box, flexDirection: "column", padding: 3 }}>
            <Typography fontWeight="bold" fontSize={24}>Payment Transfer Details</Typography>
            <Image width={"60%"} src={request.receipt} />
            <Typography fontSize={22}>Username: {request.instapayUser}</Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  acceptTicketRequest,
  fulfillTicketRequest,
  rejectTicketRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestRow);
