import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwittwerIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { connect } from "react-redux";
import { logout } from "../store/actions/authActions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { UserLogin } from "../../Pages";
import StarsIcon from "@mui/icons-material/Stars";
import YouTube from "@mui/icons-material/YouTube";

function Footer({ auth }) {
  const navigate = useNavigate();

  const token = auth.token || "";
  const header = token.split(" ");
  const role = header[0];
  const location = useLocation();

  const route = (path) => {
    navigate(path);
  };

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "var(--secPurple)",
          color: "var(--mainWhite)",
          width: "100vw",
          padding: 5,
          paddingBottom: 0,
          mt: 2,
        }}
      >
        <Grid container spacing={isMobile ? 5 : 30} mb={2}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography fontSize={20} fontWeight="bold">
              About Us
            </Typography>
            <Typography textAlign="justify" fontWeight="lighter">
              Tickit is an innovative online ticketing solution whose aim is to
              revolutionize the way events are managed by leveraging our fast,
              efficient, and easy-to-use barcode technology.
            </Typography>
          </Grid>
          {/* <Grid item xs={isMobile ? 12 : 3}>
                        <Typography fontSize={20} fontWeight="bold">Follow Us</Typography>
                        <Grid container>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FacebookIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <InstagramIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <TwittwerIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <YouTube fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FaTiktok size="30" color="var(--mainWhite)" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FaLinkedin size="30" color="var(--mainWhite)" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid> */}
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography fontSize={20} fontWeight="bold">
              Links
            </Typography>
            <Typography>
              <NavLink className="a4" to="/contact">
                {" > Contact Us"}
              </NavLink>
            </Typography>
            <Typography>
              <NavLink className="a4" to="/faqs">
                {" > FAQs"}
              </NavLink>
            </Typography>
            <Typography>
              <NavLink className="a4" to="/careers">
                {" > Join Us"}
              </NavLink>
            </Typography>
            <Typography>
              <NavLink className="a4" to="/events/request">
                {" > TICKIt-ify Your Event"}
              </NavLink>
            </Typography>
            <Typography>
              <NavLink className="a4" to="/refund-policy">
                {" > Refund Policy"}
              </NavLink>
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography fontSize={20} fontWeight="bold">
              Follow Us
            </Typography>
            {/* <Typography textAlign="justify" fontWeight={10}>
              <FacebookIcon /> TickIt Egypt
            </Typography> */}
            <Typography textAlign="justify">
              <InstagramIcon />{" "}
              <NavLink
                to={"https://www.instagram.com/tickit.events/"}
                target="_blank"
                className="a2"
              >
                @tickit.events
              </NavLink>
            </Typography>
            <Typography textAlign="justify">
              <WhatsAppIcon />{" "}
              <NavLink
                to={"https://wa.me/message/QCHGXGG2ZHTCJ1"}
                target="_blank"
                className="a2"
              >
                Tickit
              </NavLink>
            </Typography>
            {/* <Typography textAlign="justify">
              <FaTiktok /> @TickItEgypt
            </Typography> */}
          </Grid>
        </Grid>

        <hr
          style={{
            borderTop: "solid 2px var(--appBg)",
            opacity: "100%",
            margin: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: "var(--secPurple)",
          color: "var(--mainWhite)",
          width: "100vw",
          padding: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold">
              © 2025 Q R Tick For Events
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            <Typography fontWeight="bold" textAlign="center">
              <NavLink className="a2" to="/terms">
                {"Terms & Conditions"}
              </NavLink>
            </Typography>
            <Typography fontWeight="bold" textAlign="center">
              <NavLink className="a2" to="/policy">
                {"Privacy Policy"}
              </NavLink>
            </Typography>
          </Grid>
          <Grid item xs={4} sx={right_flex_box}>
            <Typography fontWeight="bold" textAlign="right">
              <NavLink
                to={"https://linktr.ee/integra.tech"}
                target="_blank"
                className="a2"
              >
                Developed By Integra
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state?.auth,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
