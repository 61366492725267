import * as React from "react";
import {
  Typography,
  Box,
  Skeleton,
  Rating,
  Button,
  Chip,
  Grid,
  Avatar,
  Paper,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QuizIcon from "@mui/icons-material/Quiz";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { card_style, centered_flex_box, main_button } from "./Styles";
import moment from "moment";
import { isMobile } from "react-device-detect";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import {
  addFeaturedEvent,
  removeFeaturedEvent,
} from "../store/actions/adminActions";

function EventCard({ event, token, addFeaturedEvent, removeFeaturedEvent }) {
  const navigate = useNavigate();
  const role = token?.split(" ")[0];

  const handleEventNav = () => {
    if (role === "Admin" || role === "Super" || role === "Partner") {
      navigate(`/event/dashboard/${event._id}`);
    } else {
      navigate(`/event/${event._id}`);
    }
  };

  const handleFeature = () => {
    if (!event.featured) {
      addFeaturedEvent({ eventId: event._id });
    } else {
      removeFeaturedEvent({ eventId: event._id });
    }
  };

  return (
    <Box sx={{ ...card_style, minWidth: "100%", maxWidth: "100%", ...centered_flex_box, flexDirection: "column" }}>
      <Box marginBottom={2} sx={{ ...centered_flex_box, width: "300px" }} onClick={handleEventNav}>
        <img
          src={
            event?.image ||
            "https://eventimagebucket.s3.eu-west-3.amazonaws.com/images/Template.jpg"
          }
          alt="Event Poster"
          height={`300x`}
          width={`300px`}
        />
      </Box>
      <Box sx={{ width: "300px", ...centered_flex_box, flexDirection: "column" }}>
        <Grid container>
          <Grid item xs={10} onClick={handleEventNav}>
            <Typography color="black" sx={{ fontSize: 24, fontWeight: "bold" }}>
              {event.title}
            </Typography>
          </Grid>
          {role === "Super" && (
            <Grid
              item
              direction="row"
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              xs={2}
            >
              <IconButton onClick={handleFeature}>
                {" "}
                {event.featured ? (
                  <StarIcon sx={{ color: "gold" }} />
                ) : (
                  <StarBorderIcon />
                )}{" "}
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Box sx={{ minWidth: "100%" }} mb={4} onClick={handleEventNav}>
          <Grid container>
            <Grid item xs={1}>
              <LocationOnIcon />
            </Grid>
            <Grid item>
              <Typography color="black" sx={{ textAlign: "justify" }}>
                {event.location}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item>
              <Typography color="black">
                {moment(event.date).format("DD MMMM @ HH:mm")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
});

const mapDispatchToProps = {
  addFeaturedEvent,
  removeFeaturedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);
