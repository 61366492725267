import * as React from "react";
import {
  Modal,
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  MainInputLabel,
  main_button,
  centered_flex_box,
  sec_button,
  confirm_button,
  reject_button,
  ter_button,
  TerInput,
} from "../../../app/components/Styles";
import {
  generateInvitations,
  generateTickets,
  generateTicketsQRs,
} from "../../../app/store/actions/adminActions";
import ReactFileReader from "react-file-reader";
import Upload from "@mui/icons-material/Upload";
import Download from "@mui/icons-material/Download";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import download from "downloadjs";
import Papa, { parse } from "papaparse";
import PopperButton from "../../../app/components/PopperButton";

export const GenerateInvitationsModal = ({
  open,
  handleClose,
  event,
  wait,
  generateInvitations
}) => {
  const [file, setFile] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const id = useParams().id;

  const handleDeleteFile = () => {
    setFile(null);
    setData([])
  };

  const handleFiles = (files) => {
    const newFile = files[0];
    setFile(newFile);
    var reader = new FileReader();
    reader.readAsText(newFile);
    reader.onload = async () => {
      const parsed = Papa.parse(reader.result, { header: true });
      const d = parsed.data.filter(
        (info) => info.name !== "" && info.phoneNumber !== ""
      )
      setData(d)
    }
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleSubmit = async (event) => {
    if (file) {
      const info = {
        eventId: id,
        data,
        category,
      };
      console.log(info)
      generateInvitations(info, handleClose)
      await timeout(500);
    } else {
      notification.error({ message: "Please Upload a valid .csv file" });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-style" sx={centered_flex_box}>
        {wait.isLoading && (
          <Box
            sx={{
              ...centered_flex_box,
              minHeight: "50vh",
              flexDirection: "column",
            }}
          >
            <CircularProgress sx={{ color: "var(--appBg)" }} />
            <Typography fontWeight="bold">
              Processing your request...
            </Typography>
          </Box>
        )}
        {!wait.isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "var(--secColor)" }}>
              <LocalActivityIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Generate invitations using data in .csv format
            </Typography>
            <Button
              fullWidth
              sx={{ my: 1, ...sec_button }}
              onClick={() =>
                download(
                  "https://eventimagebucket.s3.eu-west-3.amazonaws.com/files/tickets-sample.csv"
                )
              }
            >
              <Download /> CSV Sample
            </Button>
            <hr />
            <Box
              minWidth="100%"
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TerInput
                margin="normal"
                autoFocus
                fullWidth
                select
                labelId="num-label"
                id="category"
                label="Category"
                name="category"
                value={category}
                onChange={({ target }) => setCategory(target.value)}
                sx={{
                  color: "var(--appBg) !important",
                }}
              >
                {event?.categories?.map((cat) => (
                  <MenuItem value={cat.category}>
                    {cat.category} - Category
                  </MenuItem>
                ))}
              </TerInput>
              <Box minWidth="100%">
                <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
                  <Button fullWidth sx={{ my: 1, ...sec_button }}>
                    <Upload /> Upload .csv{" "}
                  </Button>
                </ReactFileReader>
                {file && <Chip label={file.name} onDelete={handleDeleteFile} />}
              </Box>
              <hr />
              <Typography textAlign="center">Estimated Cost: ${parseFloat(data.length * 0.14).toFixed(2)} +/- ${parseFloat(data.length * 0.07).toFixed(2)}</Typography>
              <hr />
              <Box sx={{ mt: 3 }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ ml: 2, ...confirm_button }}
                >
                  Send
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ ml: 2, ...reject_button }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  wait: state?.wait,
});

const mapDispatchToProps = { generateInvitations };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateInvitationsModal);
